
/* 
 * Fade 
 */
.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

 
/* 
 * Slide-up 
 */

.slideup-enter {
  opacity: 0.01;
  transform: translateY(-50px);
}

.slideup-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease-in;
}

.slideup-exit {
  opacity: 1;
  transform: translateY(0px);
  transition: all 300ms ease-in;
}

.slideup-exit-active {
  opacity: 0;
  transform: translateY(-50px);
  transition: all 300ms ease-in;
}

/* 
 * Slide 
 */
.slide-enter {
  opacity: 0.01;
  transform: translate(-50px);
}

.slide-enter-active {
  opacity: 1;
  transform: translate(0px);
  transition: all 300ms ease-in;
}

.slide-exit {
  opacity: 1;
  transform: translate(0px);
  transition: all 300ms ease-in;
}

.slide-exit-active {
  opacity: 0;
  transform: translate(-50px);
  transition: all 300ms ease-in;
}
 /* 
  * Slide Right
  */
.slideR-enter {
  opacity: 0.01;
  transform: translate(50px);
}

.slideR-enter-active {
  opacity: 1;
  transform: translate(0px);
  transition: all 300ms ease-in;
}

.slideR-exit {
  opacity: 1;
  transform: translate(0px);
  transition: all 300ms ease-in;
}

.slideR-exit-active {
  opacity: 0;
  transform: translate(50px);
  transition: all 300ms ease-in;
}


