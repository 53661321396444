.main-navbar {

   background-color: #20232a;
}

.dp-overlay {
  top: 46px !important;
}

.footing {

  background-color: #bd1b27 !important;
  border: 2px solid #bd1b27;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  color: white !important;
}

label {

  opacity: 1 !important;
}

.btn-exact {

   border-radius: 20px;
   border: 1px solid rgb(255, 58, 58);
   background-color: rgb(255, 58, 58);
   color: #FFFFFF;
   font-size: 12px;
   font-weight: bold;
   padding: 10px 25px;
   letter-spacing: 1px;
   transition: transform 80ms ease-in;
}

.btn-exact-default {

   border-radius: 20px;
   border: 1px solid rgb(218, 218, 218);
   background-color: rgb(241, 241, 241);
   color: #333;
   font-size: 12px;
   font-weight: bold;
   padding: 10px 25px;
   letter-spacing: 1px;
   transition: transform 80ms ease-in;
}

.btn-exact:active {
   transform: scale(0.95);
}

.btn-exact-default:active {
   transform: scale(0.95);
}

.btn-exact:focus {
   outline: none;
}

.btn-exact-default:focus {
   outline: none;
}

.loading-banner{

   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   height: 100%;
}

.breeding-rhombus-spinner {
   height: 65px;
   width: 65px;
   position: relative;
   transform: rotate(45deg);
 }

 .breeding-rhombus-spinner, .breeding-rhombus-spinner * {
   box-sizing: border-box;
 }

 .breeding-rhombus-spinner .rhombus {
   height: calc(65px / 7.5);
   width: calc(65px / 7.5);
   animation-duration: 2000ms;
   top: calc(65px / 2.3077);
   left: calc(65px / 2.3077);
   background-color: #ff1d5e;
   position: absolute;
   animation-iteration-count: infinite;
 }

 .breeding-rhombus-spinner .rhombus:nth-child(2n+0) {
    margin-right: 0;
  }

 .breeding-rhombus-spinner .rhombus.child-1 {
   animation-name: breeding-rhombus-spinner-animation-child-1;
   animation-delay: calc(100ms * 1);
 }

 .breeding-rhombus-spinner .rhombus.child-2 {
   animation-name: breeding-rhombus-spinner-animation-child-2;
   animation-delay: calc(100ms * 2);
 }

 .breeding-rhombus-spinner .rhombus.child-3 {
   animation-name: breeding-rhombus-spinner-animation-child-3;
   animation-delay: calc(100ms * 3);
 }

 .breeding-rhombus-spinner .rhombus.child-4 {
   animation-name: breeding-rhombus-spinner-animation-child-4;
   animation-delay: calc(100ms * 4);
 }

 .breeding-rhombus-spinner .rhombus.child-5 {
   animation-name: breeding-rhombus-spinner-animation-child-5;
   animation-delay: calc(100ms * 5);
 }

 .breeding-rhombus-spinner .rhombus.child-6 {
   animation-name: breeding-rhombus-spinner-animation-child-6;
   animation-delay: calc(100ms * 6);
 }

 .breeding-rhombus-spinner .rhombus.child-7 {
   animation-name: breeding-rhombus-spinner-animation-child-7;
   animation-delay: calc(100ms * 7);
 }

 .breeding-rhombus-spinner .rhombus.child-8 {
   animation-name: breeding-rhombus-spinner-animation-child-8;
   animation-delay: calc(100ms * 8);
 }

 .breeding-rhombus-spinner .rhombus.big {
   height: calc(65px / 3);
   width: calc(65px / 3);
   animation-duration: 2000ms;
   top: calc(65px / 3);
   left: calc(65px / 3);
   background-color: #ff1d5e;
   animation: breeding-rhombus-spinner-animation-child-big 2s infinite;
   animation-delay: 0.5s;
 }


 @keyframes breeding-rhombus-spinner-animation-child-1 {
   50% {
     transform: translate(-325%, -325%);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-2 {
   50% {
     transform: translate(0, -325%);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-3 {
   50% {
     transform: translate(325%, -325%);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-4 {
   50% {
     transform: translate(325%, 0);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-5 {
   50% {
     transform: translate(325%, 325%);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-6 {
   50% {
     transform: translate(0, 325%);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-7 {
   50% {
     transform: translate(-325%, 325%);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-8 {
   50% {
     transform: translate(-325%, 0);
   }
 }

 @keyframes breeding-rhombus-spinner-animation-child-big {
   50% {
     transform: scale(0.5);
   }
 }