.login, .ant-row, .ant-col {

   height: 100% !important;
}

.login-title {
   font-weight: bold;
   font-size: 22px;
   color: #bd1b27;
}
