.list-container-rounded {
   border-radius: 20px;
   padding: 5px 20px;
   background-color: #fff;
}

.pop-color .ant-popover-content .ant-popover-inner div .ant-popover-inner-content {

   padding: 0 !important;
}

.hl-box {
   border: 1.2px solid rgb(122, 169, 231);
   border-radius: 4px;
   background-color: white;
}

.excel {
   position: relative;
   top: 30px;
   animation: move 2s infinite ease-in-out;          
}

@keyframes move {
   from {
      left: 0px;
   } 
   to{
      left: -100px;
      opacity: 0;
   } 
}

.react-resizable {
   position: relative;
 }
 .react-resizable-handle {
   position: absolute;
   width: 20px;
   height: 20px;
   background-repeat: no-repeat;
   background-origin: content-box;
   box-sizing: border-box;
   background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
   background-position: bottom right;
   padding: 0 3px 3px 0;
 }
 .react-resizable-handle-sw {
   bottom: 0;
   left: 0;
   cursor: sw-resize;
   transform: rotate(90deg);
 }
 .react-resizable-handle-se {
   bottom: 0;
   right: 0;
   cursor: se-resize;
 }
 .react-resizable-handle-nw {
   top: 0;
   left: 0;
   cursor: nw-resize;
   transform: rotate(180deg);
 }
 .react-resizable-handle-ne {
   top: 0;
   right: 0;
   cursor: ne-resize;
   transform: rotate(270deg);
 }
 .react-resizable-handle-w,
 .react-resizable-handle-e {
   top: 50%;
   margin-top: -10px;
   cursor: ew-resize;
 }
 .react-resizable-handle-w {
   left: 0;
   transform: rotate(135deg);
 }
 .react-resizable-handle-e {
   right: 0;
   transform: rotate(315deg);
 }
 .react-resizable-handle-n,
 .react-resizable-handle-s {
   left: 50%;
   margin-left: -10px;
   cursor: ns-resize;
 }
 .react-resizable-handle-n {
   top: 0;
   transform: rotate(225deg);
 }
 .react-resizable-handle-s {
   bottom: 0;
   transform: rotate(45deg);
 }